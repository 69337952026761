<template>
  <section id="dashboard-analytics">
    <div>
      <div class="align-items-center">
        <b-row class="breadcrumbs-top">
          <b-col cols="3">
            <v-select
              v-model="contract"
              :options="contractOptions"
              :reduce="(val) => val.value"
              style="width: 350px"
              :clearable="false"
              @input="loadData()"
            />
          </b-col>
          <b-col cols="6" class="mr-25">
            {{ localeDate(this.chartTimestamp) }}
          </b-col>
        </b-row>
      </div>
      <br />
    </div>
    <b-row class="match-height">
      <b-col lg="4" md="12">
        <b-card text-variant="center" class="card">
          <b-card-title class="font-small-4 float-left">
            {{ $t("message.chart.projectEvolution") }}
          </b-card-title>
          <vue-apex-charts
            type="radialBar"
            :height="radialChart.height"
            :options="radialChartOptions"
            :series="radialSeries"
          /><br />
        </b-card>
      </b-col>
      <b-col lg="8" sm="6">
        <b-card text-variant="center" class="card">
          <b-card-title class="font-small-4 float-left">
            {{ $t("message.chart.hoursByTime") }}
          </b-card-title>
          <vue-apex-charts
            type="bar"
            :height="hourChart.height"
            :options="hourChartOptions"
            :series="hourSeries"
          />
        </b-card>
      </b-col>
      <b-col lg="5" sm="6">
        <b-card text-variant="center" class="card">
          <b-card-title class="font-small-4 float-left">
            {{ $t("message.chart.entryByLeft") }}
          </b-card-title>
          <vue-apex-charts
            type="bar"
            :height="entryChart.height"
            :options="entryChartOptions"
            :series="entrySeries"
          />
        </b-card>
      </b-col>
      <b-col lg="3" md="12">
        <b-card text-variant="center" class="card">
          <b-card-title class="font-small-4 float-left">
            {{ $t("message.chart.financialEvolution") }}
          </b-card-title>
          <h1
            style="
              min-height: 265px;
              margin-right: 110px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 40px;
            "
          >
            {{ data[5].evolution }}
          </h1>
        </b-card>
      </b-col>
      <b-col lg="4" md="12">
        <b-card text-variant="center" class="card">
          <b-card-title class="font-small-4 float-left">
            {{ $t("message.chart.profit") }}
          </b-card-title>
          <h1
            style="
              min-height: 265px;
              margin-right: 50px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 35px;
            "
          >
            <p v-if="profitPercentage === 0 && data[6].profit === 0">
              {{ currencyValue(data[6].profit) }}
            </p>
            <p
              v-else-if="
                profitPercentage > paramData.profitMargin && data[6].profit > 0
              "
              style="color: #6ab187"
            >
              {{ currencyValue(data[6].profit) }}
            </p>
            <p
              v-else-if="
                profitPercentage < paramData.profitMargin || data[6].profit < 0
              "
              style="color: #ac3e31"
            >
              {{ currencyValue(data[6].profit) }}
            </p>
            <sup
              ><small>({{ profitPercentage }}%)</small></sup
            >
          </h1>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <!-- Notes -->
      <b-col xl="6" md="6" v-if="projectNoteList.length != 0">
        <b-card no-body>
          <b-table-lite
            responsive
            :items="projectNoteList"
            :fields="projectNoteFields"
          >
            <template #cell(description)="data">
              <b-card-text class="font-weight-bold mb-25">
                {{ data.item.userId }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.description }}
              </b-card-text>
            </template>
            <template #cell(date)="data">
              <b-card-text class="text-nowrap">
                {{ localeDateList(data.item.date) }}
              </b-card-text>
            </template>
          </b-table-lite>
        </b-card>
      </b-col>

      <!-- Costs -->
      <b-col xl="6" md="6" v-if="projectCostList.length != 0">
        <b-card no-body>
          <b-table-lite
            responsive
            :items="projectCostList"
            :fields="projectCostFields"
          >
            <template #cell(description)="data">
              <b-card-text class="text-nowrap">
                {{ data.item.description }}
              </b-card-text>
            </template>
            <template #cell(value)="data">
              <b-card-text class="text-nowrap">
                {{ $n(data.item.value, "currency") }}
              </b-card-text>
            </template>
            <template #cell(date)="data">
              <b-card-text class="text-nowrap">
                {{ localeDateList(data.item.date) }}
              </b-card-text>
            </template>
          </b-table-lite>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardHeader,
  BTableLite,
  BCardText,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import axios from "@axios";
import URL_API from "@core/utils/env";
import getHeader from "@core/utils/auth";
import moment from "moment";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    VueApexCharts,
    BCard,
    BCardTitle,
    vSelect,
    BCardHeader,
    BTableLite,
    BCardText,
  },

  data() {
    return {
      data: [
        { radial: [0] },
        { name: "", data: [0] },
        { name: "", data: [0] },
        { name: "", data: [0] },
        { name: "", data: [0] },
        { evolution: "0.00%" },
        { profit: 0 },
      ],

      chartTimestamp: "",

      radialChart: {},
      radialSeries: [],
      radialChartOptions: {},

      entryChart: {},
      entrySeries: [],
      entryChartOptions: {},

      hourChart: {},
      hourSeries: [],
      hourChartOptions: {},

      //Variables
      projectNoteList: [],
      projectCostList: [],

      contractOptions: [],
      contract: "",

      userData: store.state.user.userData,
      paramData: store.state.user.paramData,

      profitPercentage: 0.0,
      costs: 0.0,

      //Fields
      projectNoteFields: [
        { key: "description", label: "Nota" },
        { key: "date", label: "Data" },
      ],
      projectCostFields: [
        ,
        { key: "description", label: "Custo" },
        { key: "value", label: "Valor" },
        { key: "date", label: "Data" },
      ],
    };
  },

  watch: {
    "$i18n.locale": function (newVal, oldVal) {
      this.loadData();
    },
  },

  async created() {
    await this.loadData();

    await this.loadRadialChartData();
    await this.loadEntryChartData();
    await this.loadHourChartData();

    var contractList = [];
    var contractId = [];
    await axios
      .get(`${URL_API}contract/select_list`, {
        headers: getHeader(this.userData),
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].status) {
            const element = response.data[i].name;
            const id = response.data[i].id;
            contractList.push(element);
            contractId.push(id);
            this.contractOptions.push({
              label: contractList[i],
              value: contractId[i],
            });
          }
        }
      });
  },

  methods: {
    async loadData() {
      var vm = this;

      if (this.contract === "") {
      } else {
        await axios
          .get(`${URL_API}chart/contractsResult/${this.contract}`, {
            headers: getHeader(this.userData),
          })
          .then((response) => {
            var string = response.data.chartInfo;
            var data = JSON.parse(string)[0];

            this.projectNoteList = data.notes;
            this.projectCostList = data.costs;

            this.profitPercentage = data.profitPercentage.toFixed(2);
            this.costs = data.allPaidValueCosts.toFixed(2);

            this.chartTimestamp = response.data.timestamp;

            this.data = [
              { radial: [data.percentage] },
              {
                name: vm.getColLabel("estimatedTime"),
                data: [data.projectsEstimatedTime],
              },
              {
                name: vm.getColLabel("timesheet"),
                data: [data.projectsTimeSheet],
              },
              { name: vm.getColLabel("entry"), data: [data.allInvoicesValue] },
              { name: vm.getColLabel("leaft"), data: [data.allPaidValue] },
              { evolution: data.evolution.toFixed(2) + "%" },
              { profit: data.profit.toFixed(2) },
            ];

            //console.log(this.data)
            this.radialSeries = this.data[0].radial;

            this.entrySeries = [
              {
                name: this.data[3].name,
                data: this.data[3].data,
              },
              {
                name: this.data[4].name,
                data: this.data[4].data,
              },
            ];

            this.hourSeries = [
              {
                name: this.data[1].name,
                data: this.data[1].data,
              },
              {
                name: this.data[2].name,
                data: this.data[2].data,
              },
            ];
          });
      }
    },

    async loadRadialChartData() {
      var vm = this;

      this.radialSeries = this.data[0].radial;

      this.radialChart = {
        height: 350,
      };

      this.radialChartOptions = {
        colors: ["#6a91b1"],

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.3,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 50, 100],
            inverseColors: true,
          },
        },

        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        labels: [vm.getColLabel("evolution")],
      };
    },

    async loadHourChartData() {
      this.hourChart = {
        height: 250,
      };

      this.hourSeries = [
        {
          name: this.data[1].name,
          data: this.data[1].data,
        },
        {
          name: this.data[2].name,
          data: this.data[2].data,
        },
      ];

      this.hourChartOptions = {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        colors: ["#e2eb86", "#a36ab1"],

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.3,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return val + "h";
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: -35,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },

        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
          },
          y: {
            formatter: (value) => {
              return value + "h";
            },
          },
        },

        legend: {
          position: "top",
        },

        xaxis: {
          categories: [""],
          labels: {
            show: true,
            formatter: (value) => {
              return value + "h";
            },
          },
        },
      };
    },

    async loadEntryChartData() {
      this.entryChart = {
        height: 250,
      };

      this.entrySeries = [
        {
          name: this.data[3].name,
          data: this.data[3].data,
        },
        {
          name: this.data[4].name,
          data: this.data[4].data,
        },
      ];

      this.entryChartOptions = {
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        colors: ["#6ab187", "#ac3e31"],

        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 0.3,
            opacityFrom: 0.8,
            opacityTo: 0.9,
            stops: [0, 50, 100],
          },
        },

        dataLabels: {
          enabled: true,
          enabledOnSeries: undefined,
          formatter: (val) => {
            return this.currencyValue(val);
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: -35,
          offsetY: 0,
          background: {
            enabled: true,
            foreColor: "#fff",
            borderWidth: 0,
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },

        tooltip: {
          shared: true,
          intersect: false,
          x: {
            show: true,
          },
          y: {
            formatter: (value, series) => {
              if (series.seriesIndex === 1) {
                return (
                  "Mão de obra: " +
                  this.currencyValue(value - this.costs) +
                  " / Impostos: " +
                  this.currencyValue(this.costs)
                );
              } else {
                return this.currencyValue(value);
              }
            },
          },
        },

        legend: {
          position: "top",
        },

        xaxis: {
          categories: [""],
          labels: {
            show: true,
            formatter: (value) => {
              return this.currencyValue(value);
            },
          },
        },
      };
    },

    localeDateList(value) {
      if (value) {
        if (value.day) {
          var day = value.day;
          var month = value.month;
          var year = value.year;

          var date = month + "-" + day + "-" + year;

          return this.$i18n.d(
            new Date(date.split("-")),
            "short",
            this.$i18n.locale
          );
        }
        return this.$i18n.d(
          new Date(value.split("-")),
          "short",
          this.$i18n.locale
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    localeDate(value) {
      var newFormat = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "MM-DD-YYYY"
      );
      var newHour = moment(value, "ddd MMM DD HH:mm:ss z yyyy").format(
        "HH:mm:ss"
      );

      if (newFormat != "Invalid date" && newFormat != null) {
        return (
          this.$i18n.d(
            new Date(newFormat.split("-")),
            "short",
            this.$i18n.locale
          ) +
          " - " +
          newHour
        );
      }
      return this.$i18n.t("message." + "noDate");
    },

    getColLabel(colName) {
      return this.$i18n.t("message.chart." + colName);
    },

    currencyValue(value) {
      return this.$i18n.n(value, "currency");
    },
  },
};
</script>
